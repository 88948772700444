(function () {

    document.addEventListener("DOMContentLoaded", () => {

        let toggleDescription = document.querySelectorAll('.accelerating-commerce__item .js-toggle-description'),
            itemDescription = document.querySelectorAll('.accelerating-commerce__item .accelerating-commerce__item-desc');

        toggleDescription.forEach(function (link) {
            link.addEventListener('click', (ev) => {

                itemDescription.forEach(function (item) {
                    item.classList.remove('active');
                    item.parentNode.classList.remove('active');
                });

                let targetEl = link.nextElementSibling;
                targetEl.classList.add('active');
                link.parentNode.classList.add('active');
            });
        });

    });

}());